import React from 'react';
import { css } from '@emotion/css';
import { useStyles2, useTheme } from '@grafana/ui';

type Props = {
  [key: string]: any;
};

export const WithStyles = (WrappedComponent: React.FC<any>): React.FC<Props> => {
  const HOC: React.FC = (props: Props): JSX.Element => {
    const theme = useTheme();
    const styles = useStyles2(() => {
      return {
        downloadStatus: css`
          color: ${theme.palette.blue95};
        `,
        downloadError: css`
          color: ${theme.palette.red88};
        `,
        downloadWarning: css`
          color: ${theme.palette.yellow};
        `,
        basicError: css`
          color: ${theme.palette.critical};
        `,
        spaceTop: css`
          margin-top: 10px;
        `,
        container: css`
          overflow: scroll;
          height: 100%;
          padding-bottom: 1rem;
          padding-right: 2rem;

          ::-webkit-scrollbar {
            height: 0;
          }
        `,
        codeBlock: css`
          margin: 1rem 0;
          padding: 6px;
          white-space: initial;
          display: block;
        `,
        centerTextAlign: css`
          text-align: center;
        `,
        errorWrapper: css`
          margin: 1rem 2rem;
        `,
        header: css`
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.5rem;
        `,
        headerTitle: css`
          font-weight: ${theme.typography.weight.semibold};
          font-size: ${theme.typography.size.lg};
          margin: 0 ${theme.spacing.md} 0 0;
        `,
        largerCheckboxLabelMargin: css`
          label span:last-of-type {
            margin-left: ${theme.spacing.sm};
          }
        `,
        centerControls: css`
          display: flex;
          width: 476px;
          margin: 0 auto;
        `,
        thinInlineField: css`
          display: flex;
          width: 500px;
          justify-content: flex-end;

          div {
            width: 100%;
          }
        `,
        selectField: css`
          display: flex;
          width: 400px;
          justify-content: flex-end;
        `,
        screenshotMaxWidth: css`
          max-width: 100%;
        `,
        verticalSpacing: css`
          margin: 1.2rem 0 0.6rem 0;
        `,
        marginTopMd: css`
          margin-top: ${theme.spacing.md};
        `,
        clipboardBtn: css`
          margin: '1rem auto';
          display: 'block';
        `,
        infoSection: css`
          padding: '${theme.spacing.sm} ${theme.spacing.md}';
        `,
        infoBox: css`
          border-top: 3px solid ${theme.colors.bgBlue1};
          background-color: ${theme.palette.gray15};
          padding: ${theme.spacing.md};
          margin: ${theme.spacing.md} auto;
        `,
      };
    });

    return <WrappedComponent {...props} styles={styles} />;
  };
  return HOC;
};
