import React from 'react';
import { cx } from '@emotion/css';
import { Drawer, ClipboardButton } from '@grafana/ui';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import { InfoSection } from './InfoSection';
import { Styles } from 'types';

const queryExample = require('pcap/assets/img/query-setup.png');
const queryExampleText = require('pcap/assets/img/query-example-text.png');
const timeRange = require('pcap/assets/img/time-range.png');
const ALIAS_BY_SETTING = `<MONITOR_$tag_network_monitor_name><CSTOR_$tag_cstor_ip>`;
const QUERY_TEXT = `SELECT last("active_sessions") FROM "tcp_timeslice_4_tuple" WHERE $timeFilter GROUP BY
"cstor_ip", "network_monitor_name"`;

interface Props {
  closeDrawer: () => void;
  styles: Styles;
}

const BaseInfoPanel: React.FC<Props> = ({ closeDrawer, styles }) => {
  return (
    <Drawer
      scrollableContent
      title="Settings"
      subtitle="Help with setting up cPacket PCAP panel"
      onClose={closeDrawer.bind(this)}
    >
      <div className={styles.InfoPanel}>
        <InfoSection title="Panel functions">
          <ul>
            <li>Downloading PCAP data for selected cStors.</li>
            <li>Defining filters for the traffic you are interested in.</li>
            <ul style={{ marginLeft: '2rem' }}>
              <li>Source IP</li>
              <li>Destination IP</li>
              <li>cVu port</li>
              <li>BPF filter</li>
              <li>Bidirectional filter</li>
            </ul>
            <li>Limiting downloaded file size.</li>
            <li>Searching through available cStors and network monitors</li>
          </ul>
        </InfoSection>

        <InfoSection title="Setting up query">
          <span>For the panel to work, you need to define a properly formatted query. </span>
          <br />
          <br />
          <ol>
            <li>
              <p>Example InfluxDB query in Grafana GUI query builder:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={queryExample}
                alt="Screenshot showing query Group By settings set to 'tag(cstor)' and 'tag(flow-g)'"
              />
            </li>
            <li>
              <p>Example query in raw/text mode:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={queryExampleText}
                alt="Screenshot showing text edit version of query editor'"
              />
              <ClipboardButton variant="secondary" getText={() => QUERY_TEXT} className={styles.clipboardBtn}>
                Copy to clipboard
              </ClipboardButton>
            </li>
            <li>
              <p>Required ALIAS BY setting:</p>
              <code className={styles.codeBlock}>{ALIAS_BY_SETTING}</code>
              <ClipboardButton variant="secondary" getText={() => ALIAS_BY_SETTING} className={styles.clipboardBtn}>
                Copy to clipboard
              </ClipboardButton>
            </li>
            <li>
              <p>Example query time range:</p>
              <img
                className={cx(styles.verticalSpacing, styles.screenshotMaxWidth)}
                src={timeRange}
                alt="Screenshot showing selection of time range for current dashboard"
              />
            </li>
          </ol>
        </InfoSection>

        <InfoSection title="FAQ">
          <h4>Why can&apos;t I see any/some of my cStors, or my network monitor is missing some of the observed cStors?</h4>
          <span>
            The panel shows only currently active cStors returned by your query result. Active cStors are the ones that
            contain any reading matching data request based on the panel&apos;s query and within the specified time range.
          </span>
        </InfoSection>
      </div>
    </Drawer>
  );
};

export const InfoPanel = WithStyles(BaseInfoPanel);
