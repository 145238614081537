import { PanelPlugin } from '@grafana/data';
import { SimpleOptions } from 'types';
import { Panel } from './views';

export const plugin = new PanelPlugin<SimpleOptions>(Panel).setPanelOptions(builder => {
  return builder
    .addBooleanSwitch({
      path: 'isBidirectional',
      name: 'Bidirectional',
    })
    .addTextInput({
      path: 'IPAddressFrom',
      name: 'Fixed Source IP',
      description: 'Use a fixed source IP instead of a text entry',
    })
    .addTextInput({
      path: 'IPAddressTo',
      name: 'Fixed Destination IP',
      description: 'Use a fixed destination IP instead of a text entry',
    })
    .addTextInput({
      path: 'IPAddressPort',
      name: 'Fixed Port ID',
      description: 'Use a fixed ID instead of text entry',
    })
    .addTextInput({
      path: 'cStorIP',
      name: 'cStor IP',
      description: 'Use the selected cStor from the template variable dropdown.',
    });
});
