import { LocalCstor, LocalMonitor, RawData } from '../types';

export const parseEntities = (dataList: RawData[]) => {
  const entities = dataList.reduce((acc, current) => {
    const separated =
      current.name &&
      current.name
        .split('><')
        .map((part: string) => part.replace(/[<>]/g, ''))
        // Empty strings and undefined are falsy, remove with a cast with filter
        .map((entity: string) => entity.split(/_(.+)/).filter(Boolean));
    separated &&
      acc.push(
        separated.filter((item: string[]) => {
          return item[1] !== 'undefined';
        })
      );
    return acc;
  }, [] as string[][][]);

  const parsedEntities: {
    monitors: LocalMonitor[];
    cstors: LocalCstor[];
  } = {
    monitors: [],
    cstors: [],
  };
  entities.forEach((element: any) => {
    // where element is e.g.
    // [
    //   [ 'MONITOR', 'Some name' ],
    //   [ 'CSTOR', '128.21.14.1' ],
    // ]
    const monitor = element.find((element: any) => element[0] === 'MONITOR');
    const cstor = element.find((element: any) => element[0] === 'CSTOR');
    let monitorIndex = -1;
    if (monitor) {
      monitorIndex = parsedEntities.monitors.findIndex(
        (addedMonitor: LocalMonitor) => addedMonitor.name === monitor[1]
      );
    }
    const cstorIndex = parsedEntities.cstors.findIndex((addedCstor: LocalCstor) => addedCstor.name === cstor[1]);
    if (monitorIndex >= 0) {
      parsedEntities.monitors[monitorIndex].relatedCstors.push(cstor[1]);
    } else if (monitor) {
      parsedEntities.monitors.push({
        name: monitor[1],
        relatedCstors: [cstor[1]],
        checked: false,
      });
    }
    if (cstorIndex === -1) {
      parsedEntities.cstors.push({
        name: cstor[1],
        checked: false,
      });
    }
  });
  return parsedEntities;
};

export const checkQueryResponseValidity = (dataList: RawData[]) => {
  const usesDefaultQuery = !!dataList[0] && dataList[0].alias === 'A-series';
  const emptyResults = !!dataList && dataList.length === 0;
  const resultsInTable = !!dataList[0] && 'columns' in dataList[0] && 'rows' in dataList[0];
  const incorrectResults = !!(
    !emptyResults &&
    dataList[0].name &&
    (!/(<(.*?)>){1,}/g.test(dataList[0].name) || !/<CSTOR_(?!undefined>)/g.test(dataList[0].name))
  );
  const queryWarning = {
    usesDefaultQuery,
    emptyResults,
    resultsInTable,
    incorrectResults,
  };

  return queryWarning;
};
