import React from 'react';
import { IconButton } from '@grafana/ui';

import { Styles } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';

interface Props {
  onIconClick?: () => void;
  styles: Styles;
}

const BasePanelHeader: React.FC<Props> = ({ onIconClick, styles }) => {
  return (
    <header className={styles.header}>
      <h2 className={styles.headerTitle}>cPacket Capture</h2>
      <IconButton name="question-circle" onClick={onIconClick} size="xl" />
    </header>
  );
};

export const PanelHeader = WithStyles(BasePanelHeader);
