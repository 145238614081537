import React from 'react';
import { css } from '@emotion/css';

import { Checkbox, InlineField, VerticalGroup, Input, HorizontalGroup } from '@grafana/ui';

import { RANGE_DATA_PROPERTIES } from 'pcap/constants';
import { RangeData, RangeDataValidation, SimpleOptions, Styles } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import { CstorInput } from '../CstorInput';

interface Props {
  bidirectional: boolean;
  options: SimpleOptions;
  rangeData: RangeData;
  setBidirectional: Function;
  styles: Styles;
  validation: RangeDataValidation;
  handleAddressInput: Function;
}

const BaseTemplateDefault: React.FC<Props> = ({
  bidirectional,
  options,
  rangeData,
  setBidirectional,
  styles,
  validation,
  handleAddressInput,
}) => {
  const onBidirectionalChange = (value: boolean) => {
    setBidirectional(value);
  };

  const presetConfigurationField = css`
    margin: 0;
    padding-left: 8px;
  `;

  return (
    <div className={styles.spaceTop}>
      <VerticalGroup justify="center" align="center">
        <HorizontalGroup>
        <div className={styles.largerCheckboxLabelMargin}>
          <Checkbox
            value={bidirectional}
            onChange={v => onBidirectionalChange(v.currentTarget.checked)}
            label="Bidirectional Filter"
            
          />
        </div>
        </HorizontalGroup>
        <HorizontalGroup>
        <InlineField
          label={bidirectional ? 'Between' : 'From'}
          invalid={
            !!rangeData.from && !validation[RANGE_DATA_PROPERTIES.from as keyof RangeDataValidation]
              ? true
              : undefined
          }
          grow
          className={styles.thinInlineField}
        >
          <Input
            width={50}
            type="text"
            placeholder="127.0.0.1"
            value={rangeData.from}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAddressInput(e.target.value, RANGE_DATA_PROPERTIES.from);
            }}
            className={presetConfigurationField}
          />
        </InlineField>
        </HorizontalGroup>
        <HorizontalGroup>
        <InlineField
          label={bidirectional ? 'And' : 'To'}
          invalid={
            !!rangeData.to && !validation[RANGE_DATA_PROPERTIES.to as keyof RangeDataValidation]
              ? true
              : undefined
          }
          grow
          className={styles.thinInlineField}
        >
          <Input
            width={50}
            type="text"
            placeholder="127.0.0.1"
            value={rangeData.to}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAddressInput(e.target.value, RANGE_DATA_PROPERTIES.to);
            }}
            className={presetConfigurationField}
          />
        </InlineField>
        </HorizontalGroup>
        <HorizontalGroup>
        <InlineField
          label="cVu Port Filter"
          grow
          className={styles.thinInlineField}
          invalid={
            !!rangeData.portFilter && !validation[RANGE_DATA_PROPERTIES.portFilter as keyof RangeDataValidation]
              ? true
              : undefined
          }
        >
          <Input
            width={50}
            type="text"
            placeholder="deviceId.portId"
            value={rangeData.portFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleAddressInput(e.target.value, RANGE_DATA_PROPERTIES.portFilter);
            }}
            className={presetConfigurationField}
          />
        </InlineField>
        </HorizontalGroup>
        <HorizontalGroup>
        <CstorInput
          options={options}
          rangeData={rangeData}
          validation={validation}
          styles={styles}
          handleAddressInput={handleAddressInput}
        />
        </HorizontalGroup>
      </VerticalGroup>
    </div>
  );
};

export const TemplateDefault = WithStyles(BaseTemplateDefault);
