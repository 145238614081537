import { InlineField, Input } from '@grafana/ui';
import { RANGE_DATA_PROPERTIES } from 'pcap/constants';
import React from 'react';
import { RangeDataValidation, RangeData, Styles } from 'types';
import { css} from '@emotion/css';

interface Props {
  options: any;
  rangeData: RangeData;
  validation: RangeDataValidation;
  styles: Styles;
  handleAddressInput: Function;
}

const presetConfigurationField = css`
  margin: 0;
  padding-left: 8px;
`;

export const CstorInput: React.FC<Props> = ({ options, rangeData, validation, styles, handleAddressInput }) => {
  return (
    <InlineField
      label="cStor IP"
      grow
      className={styles.thinInlineField}
      invalid={
        !rangeData.cstor || !validation[RANGE_DATA_PROPERTIES.cstor as keyof RangeDataValidation] ? true : undefined
      }
    >
      <Input
        width={50}
        type="text"
        placeholder="cStor IP"
        value={rangeData.cstor}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          handleAddressInput(e.target.value, RANGE_DATA_PROPERTIES.cstor);
        }}
        className={presetConfigurationField}
      />
    </InlineField>
  );
};
