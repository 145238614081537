import React, { useState, useEffect, useRef } from 'react';
import { PanelProps } from '@grafana/data';
import { LocalCstor, LocalMonitor, QueryWarning, RangeData, RangeDataValidation, SimpleOptions, Styles } from 'types';
import { Collapse } from '@grafana/ui';

import { AddressConfiguration } from './components/AddressConfiguration';
import { SearchSelect } from './components/SearchSelect';
import { InfoPanel } from './components/InfoPanel';
import { ErrorWarnings } from './components/ErrorWarnings';
import { PanelHeader } from './components/PanelHeader';
import { PanelControls } from './components/PanelControls';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import {
  DEFAULT_RANGE_DATA,
  DEFAULT_VALIDATION,
  RANGE_DATA_PROPERTIES,
  defaultQueryWarning,
} from 'pcap/constants';
import { parseEntities, checkQueryResponseValidity } from 'pcap/parseData';
import { getAllCstorsRelatedToMonitors, getMonitorsAfterCstorSelection } from 'pcap/shared';
import { createID } from 'pcap/utils';
import { PcapDownload } from './components/PcapDownload';

interface Props extends PanelProps<SimpleOptions> {
  styles: Styles;
}

const PcapDownloadPanel: React.FC<Props> = ({ options, data, timeRange, styles, replaceVariables }) => {
  const [bidirectional, setBidirectional] = useState(false);
  const [cstors, setCstors] = useState<LocalCstor[]>([]);
  const [downloadSize, setDownloadSize] = useState(1);
  const [isBpfValid, setIsBpfValid] = useState(true);
  const [isBpfValidationPending, setIsBpfValidationPending] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSearchPanelOpen, setIsSearchPanelOpen] = useState(false);
  const [monitors, setMonitors] = useState<LocalMonitor[]>([]);
  const [rangeData, setRangeData] = useState(DEFAULT_RANGE_DATA);
  const [trafficFiltering, setTrafficFiltering] =useState<string>('fast');
  const [queryResultsValid, setQueryResultsValid] = useState(true);
  const [queryWarning, setQueryWarning] = useState<QueryWarning>(defaultQueryWarning);
  const [validation, setValidation] = useState<RangeDataValidation>(DEFAULT_VALIDATION);
  const previousRangeData: any = useRef(DEFAULT_RANGE_DATA);
  const bpfDebounceTimeout: { current: number } = useRef(0);
  const uid = useRef(createID());

  const toggleCollapse = () => {
    if (hasEntitiesToSelect()) {
      setIsSearchPanelOpen(!isSearchPanelOpen);
    }
  };

  // update cstor selections with choices from option and from query selection
  // after cstor option updates or selection changes.
  const getCstorSelections = (cstorsFromOption: string) => {
    let selectedCstors = new Set(cstors.filter((cs: LocalCstor) => cs.checked).map((cst: LocalCstor) => cst.name));
    !!cstorsFromOption && cstorsFromOption.split(',').forEach(ip => selectedCstors.add(ip));
    return Array.from(selectedCstors).join(',');
  };

  useEffect(() => {
    const emptySelection = monitors.every(monitor => !monitor.checked) && cstors.every(cstor => !cstor.checked);
    emptySelection
      ? window.sessionStorage.removeItem(uid.current)
      : window.sessionStorage.setItem(uid.current, JSON.stringify({ monitors, cstors }));
    const selectedCstors = getCstorSelections(replaceVariables(options.cStorIP));
    setRangeData((previous: RangeData) => {
      return {
        ...previous,
        [RANGE_DATA_PROPERTIES.cstor]: selectedCstors ?? '',
        [RANGE_DATA_PROPERTIES.from]: rangeData.from ?? '',
        [RANGE_DATA_PROPERTIES.to]: rangeData.to ?? '',
        [RANGE_DATA_PROPERTIES.portFilter]: rangeData.portFilter ?? '',
      };
    });
    // eslint-disable-next-line
  }, [monitors, cstors]);

  useEffect(() => {
    // 1: validate query response
    const queryWarnings = checkQueryResponseValidity(data.series);
    const responsesValid = Object.values(queryWarnings).every(value => !value);
    setQueryResultsValid(responsesValid);
    setQueryWarning(queryWarnings);

    // 2: parse data and update state
    if (!responsesValid) {
      setMonitors([]);
      setCstors([]);
      return;
    }

    const deviceData: {
      monitors: LocalMonitor[];
      cstors: LocalCstor[];
    } = parseEntities(data.series);
    const storedDeviceData = window.sessionStorage.getItem(uid.current);

    if (!storedDeviceData) {
      setMonitors(deviceData.monitors);
      setCstors(deviceData.cstors);
      return;
    }

    const preselectedDeviceData: {
      monitors: LocalMonitor[];
      cstors: LocalCstor[];
    } = JSON.parse(storedDeviceData);

    const preselectedMonitors = deviceData.monitors.map(monitor => {
      const preselectedMonitor = preselectedDeviceData.monitors.find(pMonitor => pMonitor.name === monitor.name);
      return { ...monitor, checked: preselectedMonitor ? preselectedMonitor.checked : monitor.checked };
    });

    const cstorsSelectedByMonitors = getAllCstorsRelatedToMonitors(preselectedMonitors);

    const preselectedCstors = deviceData.cstors.map((cstor: LocalCstor) => {
      const isRelatedToPredefinedMonitor = cstorsSelectedByMonitors.includes(cstor.name);
      const savedCstor = preselectedDeviceData.cstors.find(pCstor => pCstor.name === cstor.name);
      return { ...cstor, checked: !!(isRelatedToPredefinedMonitor || savedCstor?.checked) };
    });

    const preselectedMonitorsAfterCstorSelection = getMonitorsAfterCstorSelection(
      preselectedMonitors,
      preselectedCstors
    );

    setMonitors(preselectedMonitorsAfterCstorSelection);
    setCstors(preselectedCstors);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const predefinedOptions = [options.cStorIP, options.IPAddressFrom, options.IPAddressTo, options.IPAddressPort];
    const parsedOptions = predefinedOptions.map(option => {
      const relatedDashboardVariables = option?.match(/\$\{(.*?)\}/g)?.map(variableName => {
        return { [variableName]: replaceVariables(variableName) };
      });
      const parsedValue = relatedDashboardVariables?.length
        ? replaceVariablesInString(option, relatedDashboardVariables)
        : option;
      return parsedValue;
    });
    const [cstor, from, to, portFilter] = parsedOptions;
    const selectedCstors = getCstorSelections(cstor);
    setRangeData((previous: any) => {
      return {
        ...previous,
        [RANGE_DATA_PROPERTIES.cstor]: selectedCstors ?? previous[RANGE_DATA_PROPERTIES.cstor],
        [RANGE_DATA_PROPERTIES.from]: from ?? previous[RANGE_DATA_PROPERTIES.from],
        [RANGE_DATA_PROPERTIES.to]: to ?? previous[RANGE_DATA_PROPERTIES.to],
        [RANGE_DATA_PROPERTIES.portFilter]: portFilter ?? previous[RANGE_DATA_PROPERTIES.portFilter],
      };
    });
    setBidirectional(options.isBidirectional ? true : false);
    // eslint-disable-next-line
  }, [options, replaceVariables(options.cStorIP),
    // eslint-disable-next-line
    replaceVariables(options.IPAddressFrom),
    // eslint-disable-next-line
    replaceVariables(options.IPAddressTo),
    // eslint-disable-next-line
    replaceVariables(options.IPAddressPort)]);

  useEffect(() => {
    if (
      previousRangeData &&
      previousRangeData.current &&
      (previousRangeData.current.bpf !== rangeData.bpf ||
        previousRangeData.current.vlanAgnostic !== rangeData.vlanAgnostic)
    ) {
      if (rangeData.bpf === '') {
        clearTimeout(bpfDebounceTimeout.current);
        setIsBpfValidationPending(false);
        setIsBpfValid(!rangeData.vlanAgnostic);
      } else {
        if (bpfDebounceTimeout.current) {
          clearTimeout(bpfDebounceTimeout.current);
        }
        bpfDebounceTimeout.current = window.setTimeout(validateBpf, 1500);
        setIsBpfValidationPending(true);
      }
    } else {
      setValidation(validateForm());
    }
    previousRangeData.current = rangeData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rangeData]);

  const replaceVariablesInString = (value: string, variables: any[]): string => {
    let parsedValue = value;
    variables.forEach((variable: string) => {
      const [name, value] = Object.entries(variable)[0];
      const escapedNameForRegExp = name
        .replace('$', '\\$')
        .replace('{', '\\{')
        .replace('}', '\\}');
      parsedValue = parsedValue.replace(new RegExp(`${escapedNameForRegExp}`), value);
    });
    return !!parsedValue?parsedValue.trim():parsedValue;
  };

  const validateBpf = () => {
    const validationURL = `${document.location.origin}/api/vb/validateBpfFilter?bpfFilterString=`;
    const bpfWithVlanOption = rangeData.vlanAgnostic
      ? rangeData.bpf
      : `${rangeData.bpf} or (vlan and ${rangeData.bpf}) or (vlan and ${rangeData.bpf})`;
    fetch(`${validationURL}${encodeURIComponent(bpfWithVlanOption)}`)
      .then(res => res.json())
      .then(data => {
        if (!data) {
          throw new Error('No "data" in response body.');
        }
        if (!data.hasOwnProperty('valid')) {
          throw new Error('Response data does not conform to expected format: { valid: boolean }.');
        }
        setIsBpfValid(data.valid);
      })
      .catch(error => {
        console.error('Bpf validation error: ', error);
        setIsBpfValid(false);
      })
      .finally(() => {
        setIsBpfValidationPending(false);
      });
  };

  const validateForm = (): RangeDataValidation => {
    const cstor =
      rangeData.cstor !== '' &&
      rangeData.cstor
        .toString()
        .split(',')
        .every(cs => isIPValid(cs.trim()));
    const to = rangeData.to.trim() === '' || isIPValid(rangeData.to.trim());
    const from = rangeData.from.trim() === '' || isIPValid(rangeData.from.trim());
    const portFilter = !!isCVuPortFilterValid(rangeData.portFilter.trim());
    return { cstor, to, from, portFilter };
  };

  const updateMonitorsCheckedState = (monitorsToUpdate: LocalMonitor[], cstors: LocalCstor[]) => {
    return monitorsToUpdate.map(monitor => {
      const cstorsRelatedToMonitor = cstors.filter(cstor => monitor.relatedCstors.includes(cstor.name));
      const isEveryRelatedCstorChecked = cstorsRelatedToMonitor.every(cstor => cstor.checked);
      return {
        ...monitor,
        checked: isEveryRelatedCstorChecked,
      };
    });
  };

  const toggleMonitorSelection = (toggledMonitor: LocalMonitor) => {
    const targetCheckedValue = !toggledMonitor.checked;
    let updatedMonitors = monitors.map(monitor => {
      return monitor.name === toggledMonitor.name ? { ...monitor, checked: !toggledMonitor.checked } : monitor;
    });
    const updatedCstors = cstors.map(cstor => {
      if (toggledMonitor.relatedCstors.includes(cstor.name)) {
        return {
          ...cstor,
          checked: targetCheckedValue,
        };
      }

      return cstor;
    });
    updatedMonitors = updateMonitorsCheckedState(monitors, updatedCstors);
    setCstors(updatedCstors);
    setMonitors(updatedMonitors);
  };

  const toggleCstorSelection = (toggledCstor: LocalCstor) => {
    const targetCheckedValue = !toggledCstor.checked;
    const updatedCstors: LocalCstor[] = cstors.map((cstor: LocalCstor) => {
      if (cstor.name === toggledCstor.name) {
        return {
          ...cstor,
          checked: targetCheckedValue,
        };
      }

      return cstor;
    });
    const updatedMonitors = updateMonitorsCheckedState(monitors, updatedCstors);
    setCstors(updatedCstors);
    setMonitors(updatedMonitors);
  };

  const hasEntitiesToSelect = (): boolean => {
    return !!(monitors.length || cstors.length);
  };

  const getSelectionLabel = () => {
    const selectedMonitors = monitors.filter((monitor: LocalMonitor) => monitor.checked);
    const selectedCstors = cstors.filter((cstor: LocalCstor) => cstor.checked);
    const monitorsLabel = `${selectedMonitors.length} Monitor`;
    const cstorsLabel = `${selectedCstors.length} cStor`;
    if (selectedMonitors.length === 1) {
      return `Selected: ${monitorsLabel}: ${selectedMonitors[0].name}`;
    } else if (selectedMonitors.length > 1) {
      return `Selected: ${monitorsLabel}s`;
    } else if (selectedCstors.length === 1) {
      return `Selected: ${cstorsLabel}`;
    } else if (selectedCstors.length > 1) {
      return `Selected: ${cstorsLabel}s`;
    } else if (cstors.length === 0) {
      return 'No cStors available';
    } else if (monitors.length > 0 && selectedMonitors.length === 0) {
      let multiple = monitors.length > 1;
      return multiple ? `${monitors.length} Network Monitors available` : `${monitors[0].name} monitor available`;
    } else if (monitors.length === 0 && selectedCstors.length === 0) {
      const multiple = cstors.length > 1;
      return `${cstors.length} cStor${multiple ? 's' : ''}`;
    } else {
      return 'Available Options';
    }
  };

  const areAllMonitorsSelected = () => {
    return monitors.every((monitor: LocalMonitor) => monitor.checked === true);
  };

  const areAllCstorsSelected = () => {
    return cstors.every((cstor: LocalCstor) => cstor.checked);
  };

  const toggleAllMonitors = ({ toggleOff = false }) => {
    const targetCheckedValue = toggleOff ? false : !areAllMonitorsSelected();
    let updatedMonitors: LocalMonitor[] = monitors.map((monitor: LocalMonitor) => ({
      ...monitor,
      checked: targetCheckedValue,
    }));

    const cstorsRelatedToCheckedMonitors: string[] = updatedMonitors.reduce((acc: string[], cur: LocalMonitor) => {
      cur.relatedCstors.forEach((cstor: string) => {
        if (!acc.includes(cstor)) {
          acc.push(cstor);
        }
      });

      return acc;
    }, []);

    const updatedCstors: LocalCstor[] = cstors.map((cstor: LocalCstor) => {
      if (cstorsRelatedToCheckedMonitors.includes(cstor.name)) {
        return {
          ...cstor,
          checked: targetCheckedValue,
        };
      }

      return cstor;
    });
    updatedMonitors = updateMonitorsCheckedState(updatedMonitors, updatedCstors);
    setCstors(updatedCstors);
    setMonitors(updatedMonitors);
  };

  const toggleAllCstors = ({ toggleOff = false }) => {
    const targetCheckedValue = toggleOff ? false : !areAllCstorsSelected();
    const updatedCstors = cstors.map((cstor: LocalCstor) => ({ ...cstor, checked: targetCheckedValue }));
    const updatedMonitors = updateMonitorsCheckedState(monitors, updatedCstors);

    setCstors(updatedCstors);
    setMonitors(updatedMonitors);
  };

  const isIPValid = (ip: string): boolean => {
    const regex = /^(?<ip>(?:(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(?:[0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))(?::(?<port>[0-9]{1,5}))?$/;
    const port = ip.split(':')[1];
    return regex.test(ip) && (port ? Number(port) <= 65535 : true);
  };

  const isCVuPortFilterValid = (portFilter: string) => {
    const isDeviceIdValid = '[0-9]{1,}';
    const isPortValid = '[0-9]{1,}';
    return portFilter === '' || portFilter.match(new RegExp(`^${isDeviceIdValid}[.]${isPortValid}$`));
  };

  return (
    <div className={styles.container}>
      <PanelHeader onIconClick={setIsDrawerOpen.bind(this, true)} />

      <ErrorWarnings
        queryResultsValid={queryResultsValid}
        queryWarning={queryWarning}
        validation={validation}
        rangeData={rangeData}
      />

      {isDrawerOpen && <InfoPanel closeDrawer={setIsDrawerOpen.bind(this, false)} />}

      <div>
        {hasEntitiesToSelect() && 
        <Collapse
            collapsible={hasEntitiesToSelect()}
            label={getSelectionLabel()}
            isOpen={isSearchPanelOpen}
            onToggle={toggleCollapse.bind(this)}
          >
            <SearchSelect
              areAllCstorsSelected={areAllCstorsSelected()}
              areAllMonitorsSelected={areAllMonitorsSelected()}
              cstors={cstors}
              monitors={monitors}
              toggleAllCstors={toggleAllCstors}
              toggleAllMonitors={toggleAllMonitors}
              toggleCstorSelection={(cstor: LocalCstor) => toggleCstorSelection(cstor)}
              toggleMonitorSelection={(monitor: LocalMonitor) => toggleMonitorSelection(monitor)}
            />
          </Collapse> 
        }

        <PanelControls
          downloadSize={downloadSize}
          options={options}
          setDownloadSize={setDownloadSize.bind(this)}
          setTrafficFiltering={setTrafficFiltering.bind(this)}
          trafficFiltering={trafficFiltering}
        />

        <AddressConfiguration
          bidirectional={bidirectional}
          isBpfValid={isBpfValid}
          isBpfValidationPending={isBpfValidationPending}
          options={options}
          rangeData={rangeData}
          setBidirectional={setBidirectional}
          setRangeData={setRangeData}
          type={trafficFiltering}
          validation={validation}
        />

        <PcapDownload
          bidirectional={bidirectional}
          cstors={cstors}
          downloadSize={downloadSize}
          isBpfValid={isBpfValid}
          rangeData={rangeData}
          timeRange={timeRange}
          trafficFiltering={trafficFiltering}
          validation={validation}
        />
      </div>
    </div>
  );
};

export const Panel = WithStyles(PcapDownloadPanel);
