import React from 'react';
import { Button, HorizontalGroup } from '@grafana/ui';

import { Styles, LocalCstor, RangeDataValidation, RangeData } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import { CAPTURE_DOWNLOAD_API } from 'pcap/constants';

interface Props {
  bidirectional: boolean;
  cstors: LocalCstor[];
  downloadSize: number;
  isBpfValid: boolean;
  rangeData: RangeData;
  styles: Styles;
  timeRange: any;
  trafficFiltering: string;
  validation: RangeDataValidation;
}

const BasePcapDownload: React.FC<Props> = ({
  bidirectional,
  cstors,
  downloadSize,
  isBpfValid,
  rangeData,
  styles,
  timeRange,
  trafficFiltering,
  validation,
}) => {
  const isFormValid = (): boolean => {
    const hasSelectedCstors = validation.cstor;
    const hasValidBpf = trafficFiltering !== 'bpf' || isBpfValid;
    return hasSelectedCstors && hasValidBpf && validation.to && validation.from && validation.portFilter;
  };

  const ipRangeFragment = (): string => {
    if (trafficFiltering === 'bpf' || !(validation.to && validation.from)) {
      return '';
    }

    let json: any = {};
    const port = (ip: string) => ip.split(':')[1];
    const ipWithoutPort = (ip: string) => ip.split(':')[0];

    const ipFrom = ipWithoutPort(rangeData.from);
    const portFrom = port(rangeData.from);
    const ipTo = ipWithoutPort(rangeData.to);
    const portTo = port(rangeData.to);

    if (bidirectional) {
      ipFrom && (json['ipA'] = ipFrom);
      portFrom && (json['portA'] = +portFrom);
      ipTo && (json['ipB'] = ipTo);
      portTo && (json['portB'] = +portTo);
    } else {
      ipFrom && (json['srcIp'] = ipFrom);
      portFrom && (json['srcPort'] = +portFrom);
      ipTo && (json['dstIp'] = ipTo);
      portTo && (json['dstPort'] = +portTo);
    }

    return '&cpf=' + encodeURIComponent(JSON.stringify(json));
  };

  const cVuPortFilterFragment = (): string => {
    if (!rangeData.portFilter || !validation.portFilter) {
      return '';
    }

    return '&cvuPort=' + encodeURIComponent(rangeData.portFilter);
  };

  const downloadPcap = () => {
    const start = Date.parse(String(timeRange.from));
    const end = Date.parse(String(timeRange.to));
    const removeTrailer = `&removeTrailer=false`;
    const cstorList = validation.cstor
      ? rangeData.cstor
          .split(',')
          .map(eachCstor => '&cstor=' + eachCstor)
          .join('')
      : '';
    const bpfWithVlanOption = rangeData.vlanAgnostic
      ? rangeData.bpf
      : `${rangeData.bpf} or (vlan and ${rangeData.bpf}) or (vlan and ${rangeData.bpf})`;
    const bpfParam =
      !(trafficFiltering === 'bpf') || !isBpfValid ? '' : '&bpf=' + encodeURIComponent(bpfWithVlanOption);
    const params =
      `?startTime=${start / 1000}` +
      `&endTime=${end / 1000}` +
      `&maxSize=${downloadSize * 1048576}` +
      removeTrailer +
      cstorList +
      bpfParam +
      ipRangeFragment() +
      cVuPortFilterFragment();

    const downloadURL = document.location.origin + CAPTURE_DOWNLOAD_API + params;
    window.open(downloadURL);
  };

  return (
    <HorizontalGroup justify="center">
      <Button className={styles.marginTopMd} disabled={!isFormValid()} onClick={downloadPcap}>
        Download
      </Button>
    </HorizontalGroup>
  );
};

export const PcapDownload = WithStyles(BasePcapDownload);
