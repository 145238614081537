import React from 'react';
import { HorizontalGroup, RadioButtonGroup, Field, Select } from '@grafana/ui';
import { css } from '@emotion/css';

import { Styles, DownloadSizeOption, DownloadSizes, RadioGroupOptions } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';

interface Props {
  downloadSize: DownloadSizeOption;
  options: any;
  setDownloadSize: Function;
  setTrafficFiltering: Function;
  styles: Styles;
  trafficFiltering: boolean;
}

const BasePanelControls: React.FC<Props> = ({
  downloadSize,
  options,
  setDownloadSize,
  setTrafficFiltering,
  styles,
  trafficFiltering,
}) => {
  const radioOptions: RadioGroupOptions = [
    { label: 'Fast', value: 'fast' },{ label: 'BPF', value: 'bpf' },
  ];

  const downloadSizesOptions: DownloadSizes = [
    { label: '1 MB', value: 1 },
    { label: '5 MB', value: 5 },
    { label: '10 MB', value: 10 },
    { label: 'All', value: 0 },
  ];

  return (
    <div className={styles.centerControls}>
      <HorizontalGroup justify="space-between" align="center">
        <RadioButtonGroup
          options={radioOptions}
          value={trafficFiltering}
          onChange={filteringType => setTrafficFiltering(filteringType)}
        />
        <Field
          horizontal
          label="File size"
          description="Choose maximum size"
          className={css`
            margin: 0;
          `}
        >
          <Select
            options={downloadSizesOptions}
            value={downloadSize}
            onChange={(option: DownloadSizeOption | any) => {
              setDownloadSize(option.value);
            }}
            className={css`
              margin-left: 10px;
            `}
          />
        </Field>
      </HorizontalGroup>
    </div>
  );
};

export const PanelControls = WithStyles(BasePanelControls);
