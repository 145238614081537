import { LocalCstor, LocalMonitor } from 'types';

export const getAllCstorsRelatedToMonitors = (monitors: LocalMonitor[]) => {
  return monitors.reduce((acc: string[], cur: LocalMonitor) => {
    if (cur.checked) {
      cur.relatedCstors.forEach(cstor => {
        if (!acc.includes(cstor)) {
          acc.push(cstor);
        }
      });
    }
    return acc;
  }, []);
};

export const getMonitorsAfterCstorSelection = (monitors: LocalMonitor[], cstors: LocalCstor[]) => {
  return monitors.map(monitor => {
    const hasRelatedCstorsToggledOn = monitor.relatedCstors
      .map(relatedCstor => {
        const relatedCstorObject = cstors.find(preselectedCstor => preselectedCstor.name === relatedCstor);
        return !!relatedCstorObject && relatedCstorObject.checked;
      })
      .every(val => val);
    return { ...monitor, checked: hasRelatedCstorsToggledOn };
  });
};
