import { RangeData } from 'types';

export const RANGE_DATA_PROPERTIES = {
  cstor: 'cstor',
  to: 'to',
  from: 'from',
  portFilter: 'portFilter',
  bpf: 'bpf',
  vlanAgnostic: 'vlanAgnostic',
};

export const CAPTURE_DOWNLOAD_API = '/api/vb/downloadRecordings';

export const WARNING_TOOLTIPS: { [key: string]: string } = {
  emptyResults:
    'Empty query results. There may be no Network Monitors active or the panel query may be incorrect. See ReadMe (?) for details on valid query setup.',
  incorrectResults:
    'Incorrect data received. Make sure your query matches the setup shown in Help (?) and that the correct database was selected.',
  invalidCvuFilter:
    'Incorrect "cVu Port Filter" format: the filter value should consist of two numbers separated by a full stop, for example: "2.1"',
  invalidCstorIP:
    'Incorrect "cStor IP" format: it should be a valid IPv4 address with or without a specified port, for example "127.0.12.3" or "214.12.21.12:7"',
  invalidFromIP:
    'Incorrect "From" IP format: it should be a valid IPv4 address with or without a specified port, for example "127.0.12.3" or "214.12.21.12:7"',
  invalidToIP:
    'Incorrect "To" IP format: it should be a valid IPv4 address with or without a specified port, for example "127.0.12.3" or "214.12.21.12:7"',
  emptyCstorIP: '"cStor IP" should not be empty.',
  resultsInTable: 'Query results formatted as table. Change query results formatting to time series.',
  usesDefaultQuery: 'Panel query is not set up. See ReadMe (?) for details on valid query setup.',
};

export const DEFAULT_RANGE_DATA: RangeData = {
  cstor: '',
  to: '',
  from: '',
  portFilter: '',
  bpf: '',
  vlanAgnostic: true,
};

export const DEFAULT_VALIDATION = {
  cstor: true,
  to: true,
  from: true,
  portFilter: true,
};

export const defaultQueryWarning = {
  usesDefaultQuery: false,
  emptyResults: false,
  resultsInTable: false,
  incorrectResults: false,
};
