import React from 'react';

import { RangeData, RangeDataValidation, SimpleOptions } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import { TemplateBPF } from './TemplateBPF';
import { TemplateDefault } from './TemplateDefault';

interface Props {
  bidirectional: boolean;
  isBpfValid: boolean;
  isBpfValidationPending: boolean;
  options: SimpleOptions;
  rangeData: RangeData;
  setBidirectional: Function;
  setRangeData: Function;
  type: string;
  validation: RangeDataValidation;
}

const BaseAddressConfiguration: React.FC<Props> = ({
  bidirectional,
  isBpfValid,
  isBpfValidationPending,
  options,
  rangeData,
  setBidirectional,
  setRangeData,
  type,
  validation,
}) => {
  const handleAddressInput = (val: string, property: string) => {
    setRangeData((previous: RangeData) => {
      return {
        ...previous,
        [property]: val,
      };
    });
  };

  return type === 'fast' ? (
    <TemplateDefault
      bidirectional={bidirectional}
      options={options}
      rangeData={rangeData}
      setBidirectional={setBidirectional}
      validation={validation}
      handleAddressInput={handleAddressInput}
    />
  ) : (
    <TemplateBPF
      isBpfValid={isBpfValid}
      options={options}
      isBpfValidationPending={isBpfValidationPending}
      rangeData={rangeData}
      setRangeData={setRangeData}
      validation={validation}
      handleAddressInput={handleAddressInput}
    />
  );
};

export const AddressConfiguration = WithStyles(BaseAddressConfiguration);
