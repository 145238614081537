import React from 'react';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import { Styles } from 'types';

interface Props {
  title: string;
  children: React.FC;
  styles: Styles;
}

const BaseInfoSection: React.FC<Props> = ({ title, children, styles }) => {
  return (
    <>
      <h2 className={styles.verticalSpacing}>{title}</h2>
      {children}
    </>
  );
};

export const InfoSection = WithStyles(BaseInfoSection);
