import React, { useEffect, useState } from 'react';
import { css } from '@emotion/css';

import { Collapse, Checkbox, Field, Icon, Input } from '@grafana/ui';
import { LocalMonitor, LocalCstor, Styles } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';

interface Props {
  areAllCstorsSelected: boolean;
  areAllMonitorsSelected: boolean;
  cstors: LocalCstor[];
  monitors: LocalMonitor[];
  styles: Styles;
  toggleAllCstors: Function;
  toggleAllMonitors: Function;
  toggleCstorSelection: Function;
  toggleMonitorSelection: Function;
}

const BaseSearchSelect: React.FC<Props> = ({
  areAllCstorsSelected,
  areAllMonitorsSelected,
  cstors,
  monitors,
  styles,
  toggleAllCstors,
  toggleAllMonitors,
  toggleCstorSelection,
  toggleMonitorSelection,
}) => {
  const [isCstorsCollapseOpen, setIsCstorsCollapseOpen] = useState(false);
  const [isMonitorsCollapseOpen, setIsMonitorsCollapseOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [filteredMonitors, setFilteredMonitors] = useState<LocalMonitor[]>([]);
  const [filteredCstors, setFilteredCstors] = useState<LocalCstor[]>([]);

  useEffect(() => {
    setFilteredMonitors(monitors.filter(monitor => monitor.name.toLowerCase().includes(searchValue.toLowerCase())));
    setFilteredCstors(cstors.filter(cstor => cstor.name.toLowerCase().includes(searchValue.toLowerCase())));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cstors, monitors, searchValue]);

  const openBothCollapses = () => {
    setIsCstorsCollapseOpen(true);
    setIsMonitorsCollapseOpen(true);
  };

  const handleSearchInput = (userInput: string) => {
    setSearchValue(userInput);
  };

  return (
    <>
      <Field label="Search" description="By Network Monitor name or cStor IP">
        <Input
          value={searchValue}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSearchInput(e.target.value)}
          onFocus={openBothCollapses.bind(this)}
          prefix={<Icon name="search" />}
        />
      </Field>
      <div className={styles.largerCheckboxLabelMargin}>
        <Checkbox
          value={areAllMonitorsSelected}
          onChange={toggleAllMonitors.bind(this)}
          label="All Network Monitors"
        />
      </div>
      <div className={css([styles.largerCheckboxLabelMargin], { marginBottom: '1rem' })}>
        <Checkbox value={areAllCstorsSelected} onChange={toggleAllCstors.bind(this)} label="All cStors"  />
      </div>
      <Collapse
        collapsible={true}
        label="Network Monitors"
        isOpen={isMonitorsCollapseOpen}
        onToggle={setIsMonitorsCollapseOpen.bind(this, previous => !previous)}
      >
        {filteredMonitors.length > 0 &&
          filteredMonitors.map((monitor: any, index: number) => {
            return (
              <div className={styles.largerCheckboxLabelMargin} key={`m-${index}`}>
                <Checkbox
                  value={monitor.checked}
                  onChange={toggleMonitorSelection.bind(this, monitor)}
                  label={monitor.name}
                  
                />
              </div>
            );
          })}
      </Collapse>
      <Collapse
        collapsible={true}
        label="cStors"
        isOpen={isCstorsCollapseOpen}
        onToggle={setIsCstorsCollapseOpen.bind(this, previous => !previous)}
      >
        {filteredCstors.length > 0 &&
          filteredCstors.map((cstor: any, index: number) => {
            return (
              <div className={styles.largerCheckboxLabelMargin} key={`m-${index}`}>
                <Checkbox
                  value={cstor.checked}
                  onChange={toggleCstorSelection.bind(this, cstor)}
                  label={cstor.name}
                  
                />
              </div>
            );
          })}
      </Collapse>
    </>
  );
};

export const SearchSelect = WithStyles(BaseSearchSelect);
