import React from 'react';
import { Alert } from '@grafana/ui';

import { Styles, QueryWarning, RangeDataValidation, RangeData } from 'types';
import { WARNING_TOOLTIPS } from 'pcap/constants';
import { WithStyles } from 'pcap/components/hoc/WithStyles';

interface Props {
  queryResultsValid: boolean;
  queryWarning: QueryWarning;
  styles: Styles;
  validation: RangeDataValidation;
  rangeData: RangeData;
}

type WarningSet = {
  [key: string]: boolean;
};

const BaseErrorWarnings: React.FC<Props> = ({ queryResultsValid, queryWarning, styles, validation, rangeData }) => {
  const hasValidationError = Object.values(validation).includes(false);
  const validationWarnings: WarningSet = {
    invalidToIP: !validation.to,
    invalidFromIP: !validation.from,
    invalidCvuFilter: !validation.portFilter,
  };
  if (rangeData.cstor === '') {
    validationWarnings['emptyCstorIP'] = true;
  } else {
    validationWarnings['invalidCstorIP'] = !validation.cstor;
  }

  const renderWarnings = (warnings: WarningSet) => {
    return Object.entries(warnings).map(warning => {
      const isTrue = warning[1];
      const warningKey = warning[0];

      return isTrue ? <Alert title={WARNING_TOOLTIPS[warningKey]} severity="warning" key={warningKey} /> : null;
    });
  };

  return (
    <div className={styles.errorWrapper}>
      {!validation.cstor && !queryResultsValid && renderWarnings(queryWarning)}
      {hasValidationError && renderWarnings(validationWarnings)}
    </div>
  );
};

export const ErrorWarnings = WithStyles(BaseErrorWarnings);
