import React from 'react';
import { Checkbox, HorizontalGroup, InlineField, Input, VerticalGroup } from '@grafana/ui';

import { RANGE_DATA_PROPERTIES } from 'pcap/constants';
import { RangeData, RangeDataValidation, SimpleOptions, Styles } from 'types';
import { WithStyles } from 'pcap/components/hoc/WithStyles';
import { CstorInput } from '../CstorInput';
import { css } from '@emotion/css';

interface Props {
  isBpfValid: boolean;
  options: SimpleOptions;
  isBpfValidationPending: boolean;
  rangeData: RangeData;
  setRangeData: Function;
  styles: Styles;
  validation: RangeDataValidation;
  handleAddressInput: Function;
}

const bpfField = css`
  display: flex;
  width: 500px;
  justify-content: flex-end;
`;

const BaseTemplateBPF: React.FC<Props> = ({
  isBpfValid,
  options,
  isBpfValidationPending,
  rangeData,
  setRangeData,
  styles,
  validation,
  handleAddressInput,
}) => {

  const onAgnosticVlanChange = (value: boolean) => {
    setRangeData((previous: RangeData) => {
      return {
        ...previous,
        [RANGE_DATA_PROPERTIES.vlanAgnostic]: value,
      };
    });
  };

  return (
    <div className={styles.spaceTop}>
      <VerticalGroup justify="center" align="center">
        <div className={styles.largerCheckboxLabelMargin}>
          <Checkbox
            value={rangeData.vlanAgnostic}
            onChange={v => onAgnosticVlanChange(v.currentTarget.checked)}
            label="VLAN Agnostic"
          />
        </div>
        <HorizontalGroup>
          <InlineField label="BPF" className={bpfField} loading={isBpfValidationPending} invalid={!isBpfValid}>
            <Input type="text" className={bpfField} placeholder={'host 127.0.0.1'} value={rangeData.bpf} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {handleAddressInput(e.target.value, RANGE_DATA_PROPERTIES.bpf);}}
              
            />
          </InlineField>
        </HorizontalGroup>
        <HorizontalGroup>
          <CstorInput
            options={options}
            rangeData={rangeData}
            validation={validation}
            styles={styles}
            handleAddressInput={handleAddressInput}
          />
        </HorizontalGroup>
      </VerticalGroup>
    </div>
  );
};

export const TemplateBPF = WithStyles(BaseTemplateBPF);
